import React from 'react';
import ResumePDF from '../files/Resume - Edgar Galvan Cuesta.pdf'
import ResumeDOCX from '../files/Resume - Edgar Galvan Cuesta.docx'
import Codewars from '../images/codewars-logo.png'

import {AiOutlineFileWord, AiOutlineFilePdf, AiOutlineMail, AiOutlineLinkedin} from 'react-icons/ai'
import {FaGithubSquare} from 'react-icons/fa'
import {SiCodewars} from 'react-icons/si'
import {FiGithub} from 'react-icons/fi'


class Bottom extends React.Component{
    render = () =>{
        return <footer className="bottom text-center rounded navbar navbar-dark" style={{backgroundColor: '#262626'}}>



            <div className="col-sm-4 b-el">

                <h6 className="text-white"><span className="border-bottom">Extracurricular</span></h6>

                <a className="col-sm" href="https://github.com/EdgarGC123" target="_blank" rel="noreferrer">
                    {/* <i className="fab fa-github fa-3x" aria-hidden="true"></i> */}
                    <FiGithub className="BottomIcon"/>
                </a>
                <a className="col-sm" href="https://www.codewars.com/users/EdgarGC" target="_blank" rel="noreferrer">
                    {/* <img src={Codewars} width="48px" style={{marginTop: '-25px'}}/> */}
                    <SiCodewars className="BottomIcon"/>
                </a>
                <a className="col-sm hidethis" href="https://leetcode.com/egalvancuesta/" target="_blank" rel="noreferrer">
                    <small>LeetCode</small>
                </a><br/>
                <a className="col-sm hidethis" href="https://cssbattle.dev/player/edgargc" target="_blank" rel="noreferrer">
                    <small>CSSBattles</small>
                </a>

            </div>



            <div className="col-sm-4 b-el">
                    <h6 className="text-white"><span className="border-bottom">Contact Me</span></h6>
                    <a className="col-sm" href="https://www.linkedin.com/in/edgar-galvan-cuesta-476740110/" target="_blank" rel="noreferrer">
                        {/* <i className="fab fa-linkedin fa-3x" aria-hidden="true"></i> */}
                        <AiOutlineLinkedin className="BottomIcon"/>
                    </a>
                    <a className="col-sm" href={`mailto:Edgar.GalvanCuesta@gmail.com?body=Hello Edgar,%0D%0A%0D%0AI found your website on Heroku.`}  target="_blank" rel="noreferrer">
                        {/* <i class="fas fa-envelope-square fa-3x"></i> */}
                        <AiOutlineMail className="BottomIcon"/>
                    </a>
                {/* <p>Copyright © 2021 Bootstrap 4</p> */}
            </div>



            <div className="col-sm-4 b-el">
                <h6 className="text-white"><span className="border-bottom">Resume</span></h6>
                <a className="col-sm" href={ResumePDF} download="Resume - EdgarGC">
                    {/* <i class="far fa-file-pdf fa-3x"></i> */}
                    <AiOutlineFilePdf className="BottomIcon"/>
                </a>
                <a className="col-sm" href={ResumeDOCX} download="Resume - EdgarGC">
                    {/* <i class="far fa-file-word fa-3x" aria-hidden="true"></i> */}
                    <AiOutlineFileWord className="BottomIcon"/>
                </a>
            </div>



        </footer>
    }
}

export default Bottom;