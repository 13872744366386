import React from 'react';
import Proj1Pic from '../images/Avatar Legends.jpeg';
import Proj2Pic from '../images/Bucket List Adventure.png';
import Proj3Pic from '../images/Chisme App.png';
import DisplayProject from './DisplayProject';
import MyA from "./MyA";

class Portfolio extends React.Component {
    static defaultProps = {
        mainTitle: "Projects Portfolio",
        myGAP: {
            GAPtitle: "Projects Completed At General Assembly",
            proj1: { title: "Project #1: Avatar Legends", desc: "My first project at GA was building our first app using HTML, CSS, JavaScript and JQuery. This is hosted on GitHub Pages. AJAX is also used to make an external data source request to an Avatar The Last Airbender API. I used <a href='https://last-airbender-api.herokuapp.com/' target='_blank' rel='noreferrer'>https://last-airbender-api.herokuapp.com/</a> as my source. The app includes a responsive design that will accomodate the website for mobile devices. Lastly, I implemented one of the complex user interface modules, Modal, which allows a pop-up window to show further information on any character." },
            proj2: { title: "Project #2: Bucket List Adventure", desc: "My second project at GA was working on a full-stack application unsing Node.js, Mongoose, Express and EJS (Embedded JavaScript). This was deployed and hosted on Heroku and Mongo DB Atlas. I followed the MVC (Models, Views, Controllers) structure. This app included all seven RESTful Routes (GET, POST, PUT, DELETE, PATCH) for the back-end and full CRUD (Create, Read, Update, Delete) for the front-end." },
            proj3: { title: "Project #3: Chisme", desc: "My third project at GA was working with a group on a full-stack application. We used MERN Stack, which includes: Node.JS, Mongoose, Express, and React. This was deployed and hosted on Heroku and Mongo DB Atlas as well. I followed the MVC (Models, Views, Controllers) structure. This app also includes all seven RESTful Routes (GET, POST, PUT, DELETE, PATCH) for both creating posts comments. Full CRUD (Create, Read, Update, Delete) was also implemented in the front-end to accomodate for creating posts and comments. Lastly we learned a new web tool to use called SocketIO which allowed our comments for any post to be in real-time." },
            proj4: { title: "Project #4: Interesting List of Phones", desc: "My fourth project at GA was working in a small team of two." },
            finalProj: { title: "Final Project: EGC Personal Portfolio", desc: "My final project at GA was both creating a portfolio whilst using any of the technologies we had learned. I chose PERN, a stack combination we hadn't used yet but I decided to take on a challenge. This includes Postgres, ExpressJS, React and NodeJS. Go ahead and check out my site!" }
        }
    }
    render = () => {
        const { mainTitle, myGAP } = this.props

        return (
            <div className="projects-container">
                <div className="proj-sub">
                    <br /><br />
                    <h1 className="title text-white">{mainTitle}</h1>
                    <h3 className="title">{myGAP.GAPtitle}</h3><br /><br /><br /><br />
                    <DisplayProject title={myGAP.proj1.title} desc={myGAP.proj1.desc} image={Proj1Pic} webLink="https://edgargc123.github.io/" borderClass="border-info" setDangerous={true}></DisplayProject>
                    <br /><br /><br /><br /><br /><br /><br /><br />
                    <DisplayProject title={myGAP.proj2.title} desc={myGAP.proj2.desc} image={Proj2Pic} webLink="https://project2h.herokuapp.com/bucket-list-adventure" borderClass="border-primary" setDangerous={false}></DisplayProject>
                    <br /><br /><br /><br /><br /><br /><br /><br />
                    <DisplayProject title={myGAP.proj3.title} desc={myGAP.proj3.desc} image={Proj3Pic} webLink="https://chisme-proj.herokuapp.com/" borderClass="border-danger" setDangerous={false}></DisplayProject>
                    <br /><br /><br /><br /><br /><br /><br /><br />
                    {/* <DisplayProject title={myGAP.proj4.title} desc={myGAP.proj4.desc} image={"#"} webLink="#" borderClass="border-warning" setDangerous={false}></DisplayProject>
                    <br/><br/><br/><br/><br/><br/><br/><br/>  */}
                    <DisplayProject title={myGAP.finalProj.title} desc={myGAP.finalProj.desc} setDangerous={false}></DisplayProject>
                    <br /><br /><br /><br />
                </div>
            </div>
        )
    }
}

export default Portfolio;