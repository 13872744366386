import React from 'react';

class PhotoContent extends React.Component{
    render = () =>{
        return <div>
            <h2 className="title is-2">List of Photos</h2>
            {/* create video button for admin only */}

            { this.props.admin ?
            <div>
            <button type="button" className="btn btn-secondary" data-toggle="modal" data-target="#createModalPhoto">
                Create New Photo
            </button>
            {/* Modal div to create a new video */}
            <div className="modal fade" id="createModalPhoto" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-header">

                    <h2 className="control">Create Photo</h2>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form onSubmit={this.props.createPhoto}>
                        <input onChange={this.props.changeHandler} name = "header" type="text" placeholder="Heading" /><br/>
                        <input onChange={this.props.changeHandler} name = "description" type="text" placeholder="Description" /><br/>
                        <input onChange={this.props.changeHandler} name = "photo" type="text" placeholder="Photo URL"/><br/>
                        <input className="btn btn-success" type="submit" value="Create Photo" />
                    </form>
                  </div>
                  <div className="modal-footer">
                    {/* <button type="button" className="btn btn-primary">Save changes</button> */}
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>
            </div>
            : null }
            {/* end of if else for admin */}
            <br/>
            <br/>
            <br/>
            {/* list of videos begin */}
            <ul>
            {
                this.props.myPhotos.map(
                    (photo, index) => {
                        return <li key={index}>
                            <h4>{photo.header}</h4>
                            {photo.description}<br/><br/>
                                <img src={photo.photo}/>

                            {/* button for the modal for admin only*/}
                            { this.props.admin ?
                            <div>
                            <button type="button" className="btn btn-secondary" data-toggle="modal" data-target={`#exampleModalCenter2${photo.id}`}>
                                Edit This photo
                            </button>

                            <div className="modal fade" id={`exampleModalCenter2${photo.id}`} tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="exampleModalCenterTitle">Edit This Photo</h5>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <form id={photo.id} onSubmit={this.props.updatePhoto}>
                                                <input onChange={this.props.changeHandler} name = "header" type="text" defaultValue={photo.header} /><br/>
                                                <input onChange={this.props.changeHandler} name = "description" type="text" defaultValue={photo.description} /><br/>
                                                <input onChange={this.props.changeHandler} name = "photo" type="text" defaultValue={photo.photo}/><br/>
                                                <input type="button" className="btn btn-success" type="submit" value="Update Photo" />
                                            </form>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                            {/* <button type="button" className="btn btn-primary">Save changes</button> */}
                                            <button type="button" className="btn btn-danger" value={photo.id} onClick={this.deletePhoto}>DELETE</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                            : null}
                            <br/><br/><br/><br/>
                        </li>
                    }
                )
            }
            </ul>
        </div> 
    }
}

export default PhotoContent;