// import logo from './logo.svg';
import './App.css';
import React from 'react';
import axios from 'axios';
// import Admin from './components/Admin.js';

import Top from './components/Top.js';
import HomePage from './components/HomePage.js';
import Portfolio from './components/Portfolio.js';
import Bottom from './components/Bottom.js'

import Video from './components/Content.js';
import PhotoContent from './components/PhotoContent.js';

import {Switch, Route} from 'react-router-dom';
import {Redirect} from 'react-router-dom';


class App extends React.Component {
    state = {
        vids:[],
        photos: [],
        user: true, //this turns off and on the create video aspect of the tool.
        page: 1
    }

componentDidMount = () => {
    // this.loadVideos();
    // this.loadPhotos();
}

loadVideos = () =>{
    axios.get('/videocontent').then(
        (response) => {
            this.setState({
                vids:response.data
            })
        }
    )
}
loadPhotos = () =>{
    axios.get('/photocontent').then(
        (response) => {
            this.setState({
                photos:response.data
            })
        }
    )
}

createVideo = (event) => {
    event.preventDefault();
    console.log("we are creating vid")
    axios.post(
        '/videocontent',
        {
            header:this.state.header,
            description:this.state.description,
            link:this.state.video
        }
    ).then(
        (response) => {
            this.setState({
                vids:response.data
            })
        }
    )
}
createPhoto = (event) =>{
    event.preventDefault();
    console.log("we are in creating a photo")
    axios.post(
        '/photocontent',
        {
            header:this.state.header,
            description:this.state.description,
            photo:this.state.photo
        }
    ).then(
        (response) => {
            this.setState({
                photos:response.data
            })
        }
    )
}

deleteVideo = (event) => {
    console.log("we are deleting vid")
    axios.delete('/videocontent/' + event.target.value).then(
        (response) => {
            this.setState({
                vids :response.data
            })
        }
    )
}
deletePhoto = (event) =>{
    console.log("we are deleting photo")
    axios.delete('/photocontent/' + event.target.value).then(
        (response) => {
            this.setState({
                photos :response.data
            })
        }
    )
}

updateVideo = (event) => {
    event.preventDefault();
    console.log("we are here")
    const id = event.target.getAttribute('id');
    axios.put(
        '/videocontent/' + id,
        {
            header:this.state.header,
            description:this.state.description,
            link:this.state.link
        }
    ).then(
        (response) => {
            this.setState({
                vids:response.data,
            })
        }
    )
}
updatePhoto = (event) =>{
    event.preventDefault();
    console.log("we are updating a photo")
    const id = event.target.getAttribute('id');
    axios.put(
        '/photocontent/' + id,
        {
            header:this.state.header,
            description:this.state.description,
            photo:this.state.photo
        }
    ).then(
        (response) => {
            this.setState({
                vids:response.data,
            })
        }
    )
}

changeHandler = (event) => {
    // console.log("We are typing");
    console.log(event.target.name);
    this.setState(
        {
            [event.target.name]:event.target.value
        }
    )
}

render = () => {
    return <div className="master">
        <Top changePageHandler={this.changePageHandler }/>
        { /*this.state.user ? //for later to test if this user is an admin.
            <Admin createVideo={this.createVideo} changeHandler={this.changeHandler}></Admin>
            : null*/
        }
        <div className="content">
        <Switch>
                <Route exact path="/" component={HomePage} />
                <Route exact path="/homepage" component={HomePage/*Home EGC Presentation Page */} />
                <Route exact path="/portfolio" component={Portfolio/*App Presentations*/} />
                <Route exact path="/egc/video" render={()=><Video admin={this.state.user} createVideo={this.createVideo} updateVideo={this.updateVideo} deleteVideo={this.deleteVideo} changeHandler={this.changeHandler} myVids={this.state.vids}/>} />
                <Route exact path="/egc/photo" render={()=><PhotoContent admin={this.state.user} createPhoto={this.createPhoto} updatePhoto={this.updatePhoto} changeHandler={this.changeHandler} myPhotos={this.state.photos}/>}/>
        </Switch>
        </div>
        <Bottom/>
        </div>
    }
}


export default App;
