import React from 'react';
import myLogo from '../images/Edgar Colorful White Transparent.png';
import {NavLink} from 'react-router-dom';
// import myLogoSqr from '../images/rsz_1edgar_colorful_white_transparent.png'




class Top extends React.Component{
    render = () =>{
        return (
        <header className="fixed-top rounded-bottom">
            <nav className="rounded-bottom navbar navbar-expand-lg navbar-dark" style={{backgroundColor: '#262626'}}>
                <img src={myLogo} width="125" />
                {/* <a class="navbar-brand" href="#">Navbar</a> */}
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                    <div className="navbar-nav">
                    {/* <a class="nav-item nav-link active" href="#">Home <span class="sr-only">(current)</span></a> */}
                    {/* <a name="1" className="nav-item nav-link" href="homepage" onClick={this.props.changePageHandler}>Home Page</a>
                    <a name="2" className="nav-item nav-link" href="portfolio" onClick={this.props.changePageHandler}>Project Portfolio</a> */}
                    <NavLink className="nav-item nav-link" exact to="/">Home Page</NavLink>
                    <NavLink className="nav-item nav-link" to="/portfolio">Project Portfolio</NavLink>
                    {/* <a name="3" className="nav-item nav-link" href="egc/video" onClick={this.props.changePageHandler}>EGC Videos</a>
                    <a name="4" className="nav-item nav-link" href="egc/photo" onClick={this.props.changePageHandler}>EGC Photos</a>
                    <a className="nav-item nav-link disabled" href="#">Web Development Projects (Under Maintanence)</a> */}
                    </div>
                </div>
            </nav>
        </header>
        )
    }
}

export default Top;