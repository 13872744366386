import MyA from './MyA';

export default function DisplayProject(props){
    DisplayProject.defaultProps = {//this isn't currently working
        borderClass: "",
        image: "",
        webLink: null
    }
    //no image, no link, no borderClass
    const {title, desc, image, webLink, setDangerous, borderClass} = props
    const imgAlt = title.slice(0,10)
    return (
        <div>
            <h4 className="text-white">{title}</h4>

            {setDangerous ? 
                <p dangerouslySetInnerHTML={{__html: desc}}></p>
                :
                <p>{desc}</p>}
                
            {webLink && image ?
                <MyA link={webLink}><img  className={`myProjImage border ${borderClass}`} src={image} alt={imgAlt}/></MyA>
                :
                null
            }
        </div>
    );
}