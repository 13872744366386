import React from 'react';

class Content extends React.Component{
    render = () =>{
        return <div>
            <h2 className="title is-2">List of Videos</h2>
            {/* create video button for admin only */}

            { this.props.admin ?
            <div>
            <button type="button" className="btn btn-secondary" data-toggle="modal" data-target="#createModal">
                Create New Video
            </button>
            {/* Modal div to create a new video */}
            <div className="modal fade" id="createModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-header">

                    <h2 className="control">Create Video</h2>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <form onSubmit={this.props.createVideo}>
                        <input onChange={this.props.changeHandler} name = "header" type="text" placeholder="Heading" /><br/>
                        <input onChange={this.props.changeHandler} name = "description" type="text" placeholder="Description" /><br/>
                        <input onChange={this.props.changeHandler} name = "video" type="text" placeholder="Link"/><br/>
                        <input className="btn btn-success" type="submit" value="Create Video" />
                    </form>
                  </div>
                  <div className="modal-footer">
                    {/* <button type="button" className="btn btn-primary">Save changes</button> */}
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>
            </div>
            : null }
            {/* end of if else for admin */}
            <br/>
            <br/>
            <br/>
            {/* list of videos begin */}
            <ul>
            {
                this.props.myVids.map(
                    (video, index) => {
                        return <li key={index}>
                            <h4>{video.header}</h4>
                            {video.description}<br/><br/><br/>
                            <div className="embed-responsive embed-responsive-16by9">
                                <iframe title={video.id} className="embed-responsive-item" width="791" height="445" src={video.link} frameBorder="1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>

                            {/* button for the modal for admin only*/}
                            { this.props.admin ?
                            <div>
                            <button type="button" className="btn btn-secondary" data-toggle="modal" data-target={`#exampleModalCenter1${video.id}`}>
                                Edit This Video
                            </button>

                            <div className="modal fade" id={`exampleModalCenter1${video.id}`} tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="exampleModalCenterTitle">Edit This Video</h5>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            <form id={video.id} onSubmit={this.props.updateVideo}>
                                                <input onChange={this.props.changeHandler} name = "header" type="text" defaultValue={video.header} /><br/>
                                                <input onChange={this.props.changeHandler} name = "description" type="text" defaultValue={video.description} /><br/>
                                                <input onChange={this.props.changeHandler} name = "link" type="text" defaultValue={video.link}/><br/>
                                                <input type="button" className="btn btn-success" type="submit" value="Update Video" />
                                            </form>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                            {/* <button type="button" className="btn btn-primary">Save changes</button> */}
                                            <button type="button" className="btn btn-danger" value={video.id} onClick={this.props.deleteVideo}>DELETE</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                            : null}
                            <br/><br/><br/><br/>
                        </li>
                    }
                )
            }
            </ul>
        </div> 
    }
}

export default Content;