import React from 'react';
import myMainImage from "../images/Edgar's Profile Pic.JPG"
import homePageImage from "../images/Chicago-bean-egc.jpeg"
import { DiHtml5, DiCss3Full, DiJavascript1, DiReact, DiNodejsSmall, DiNpm, DiPython, DiJava, DiPhp, DiGitBranch, DiGithubBadge, DiPostgresql, DiMongodb, DiDjango, DiDatabase, DiDocker } from 'react-icons/di'
import { SiCplusplus } from 'react-icons/si'

class HomePage extends React.Component {
    static defaultProps = {
        personalInfo: {
            myFullName: "Edgar Galvan Cuesta",
            title1: "Software Engineer / Full Stack Developer",
            title2: "Your Local Chicago Full Stack Developer, Filmmaker & Photographer",
            brandStatement: "I'm an enthusiastic Software Engineer with experience working in various languages and refactoring code for high-volume businesses. Helped increased code efficiency and gained customer confidence by building rapport with clients, working in a timely manner, and providing the desired outcome."
        },
        mySkills: ['fa-html5:HTML5', 'fa-css3:CSS3', 'fa-js:JavaScript', 'fa-react:React', 'fa-node-js:NodeJS', 'fa-cuttlefish:C++', 'fa-python:Python', 'fa-java:Java', 'fab fa-php:php', 'fa-git:git', 'fa-github:Github', 'fas fa-database:SQL', 'fas fa-database:postgreSQL', 'fab fa-envira:MongoDB'],
        mySkills2: [[<DiHtml5 />, "HTML5"], [<DiCss3Full />, "CSS3"], [<DiJavascript1 />, "JavaScript"], [<DiReact />, "React"], [<DiNodejsSmall />, "NodeJS"], [<DiNpm />, "npm"], [<SiCplusplus />, "C++"], [<DiPython />, "Python"], [<DiDjango />, "Django"], [<DiDocker />, "Docker"], [<DiJava />, "Java"], [<DiPhp />, "PHP"], [<DiGitBranch />, "git"], [<DiGithubBadge />, "Github"], [<DiDatabase />, "SQL"], [<DiPostgresql />, "postgreSQL"], [<DiMongodb />, "MongoDB"]
        ],
        myImages: {
            hpBean: homePageImage,
            hpBean2: "https://live.staticflickr.com/8433/7512090024_da4300ebf5_4k.jpg",//The Bean Image
            myFace1: "https://media-exp1.licdn.com/dms/image/C5603AQGIea09Ujg2EA/profile-displayphoto-shrink_800_800/0/1517804511213?e=1619654400&v=beta&t=BByC-1BSp94KRM54vs5SifKJBmd5Bl6d6BfBhhzI_oE", //LinkedIn profile img
            myFace2: "https://media-exp1.licdn.com/dms/image/C4E35AQEzybKCEY8BIg/profile-framedphoto-shrink_200_200/0/1618943103705?e=1620255600&v=beta&t=LyosK_rnrd2eQ07slLq3JFgF1ExF6hg4fz9N6j6W4wU", //LinkedIn with Open To Work
            myFace3: "https://pbs.twimg.com/profile_images/1234302351055474689/-V69SxLr_400x400.jpg"//Twitter profile img
        },
        myHistory: {
            story1: { title: "DeVry University", desc: "I received my Bachelor's of Science at DeVry University. I learned start with basic functional programming and Object Oriented Programming using C++. Moved on towards the Big O Notation, hierarchies, trees, nodes, structures and algorithms using Java. I continued to study how using programming languages worked and used a small ARM chip to control LED's on a board based on computer input using Objective C. I later moved into Assembly where we dove into deeper concepts as to what a language compiler is really doing behind the scenes. This is what kicked off my interest and I knew I needed to focus in on software. After three years, I graduated with a 3.7 out of 4.0 cumulative GPA." },
            story2: { title: "vAuto", desc: "I started my first job as a Level 1 Engineer. Here I did a mix of different roles, from taking phones calls in technical support, to teaching clients about our tools, being a part of our migration team, as well as our email team, and taking deep dives into SQL Programming. Here is where I spent the majority of my time as a Level 2 Engineer, making special account adjustments, working with high-end clients and accessing hidden tables in order to work with more complex account changes. I worked here for 3 years before deciding to focus in on software engineering and move away from the support aspect." },
            story3: { title: "General Assembly", desc: "After hearing good recommendations via word of mouth and from a few different review sites, I decided to take this bootcamp as a way to challenge myself. This was a great opportunity to expand my existing knowledge along with learning new tools and languages that I hadn't used yet. After three months, I've learned what it means to work in both front end development as well as back end development. Languages and tools learned include Github, Heroku, Mongo Atlas, HTML, CSS (Bootstrap, Skeleton, Normalize), JavaScript (jQuery, JSON), Express (Embedded JS), MongoDB (MongoD, Mongo, Mongoose), AJAX, PHP, SQL and React." },
            story4: { title: "DePaul University", desc: "As we slowly roll back to our normal lives, I wish to continue furthering my studies by complete my masters at DePaul Universtiy. This will no doubt solidify key concepts on lower level programs to get an in depth full understanding of building robust, and well refined code, which will serve well in my future." },
            story5: { title: "Here Comes Treble App", desc: "After completing General Assembly and continuing my learning experience with new friends, I decided to attend a 3-day Hackathon event held by General assembly. I signed up and attended in May where they partnered me with a team; two UX/UI Designers, one Data Scientist and four Software Engineers. We were given the task to create learning tool for children, and as a team, we came up with a Learn Piano App. The UX/UI designers and Data Scientist worked together in research, interviews, design, usibility testing, and more in order to come up with the best solution for the development team to take over after the hand-off. With less than 40 hours before presentations, the dev team built and deployed the initial version of our app which can be found <a href='https://here-comes-treble.netlify.app/' target='_blank' rel='noreferrer'>here!</a> Tools used include React, Tone NPM package, react-router, Express.js and MongoDB" },
            story6: { title: "Hip Hop Bingo App", desc: "Immediately after the Hackathon, I had my first freelance job lined up. My client, Bak Zoumanigui, had a tool called Intuiface that helped him create a series of images to iterate through as slides, similar to powerpoint but with more features and functionality which included creating labels, buttons that would save data, and a full excel sheet that he would have to update whenever he wanted to change a few things. In order to make this easier, he hired myself and my partner as contract software engineers to be able to easily run the app off the web and make adjustments simpler instead of using excel tables. Since most of the UI was already provided form Intuiface, we created the entire app using React, react-router and CSS." },
            story7: { title: "Barraza Attorney App", desc: "After networking through friends, I found my next freelance job. I met with a well established Attorney, Moises Barraza, who found a portion of his work to be repetitive enough to convert into a software tool. He offered me a contract job and after going over a general idea of what his goals and intentions were, I knew I needed a full team to help tackle the massive task at hand. I reached out to my old 'Here Comes Treble' team to see if there was anyone willing to be a part of this larger project and I was able to assemble a team of seven. One UX/UI Designer who is also acting Project Coordinator, one Graphic Designer, and three Software Engineers with myself also acting Project Manager. We began mid-August of 2021 with meetings to determine MVP and start with the UX/UI research portion." }
        }
    }





    render = () => {
        const { personalInfo, mySkills, myImages, myHistory } = this.props
        //INCLUDE: mySkills: Postgres (elephant), ExpressJS, JQuery, maybe a better c++
        return (
            <div>
                {/* Main landing page section */}
                <div className="top-images">
                    <img src={myImages.hpBean} className="Top-image" alt="mainImage" /><br /><br /><br />
                </div>
                <div className="profileContainer">
                    <img className="rounded-circle profilePic" src={myMainImage} width="150" height="150" alt="personalImage"></img></div><br />
                <h1 className="title is-1 text-white">{personalInfo.myFullName}</h1>
                <h4 className="title is-3">{personalInfo.title1}</h4>

                {/* Brand Statement */}
                <br /><br /><br /><br />
                <div className="container">
                    <h3 className="border-bottom text-white text-center">Personal Brand Statement</h3>
                    <br /><br />
                    <p className="blockquote text-left">{personalInfo.brandStatement}</p>
                </div>

                {/* Skills */}
                <br /><br /><br /><br />
                <div className="container">
                    <h3 className="border-bottom text-white text-center">Developer Skills</h3>
                    <br /><br />
                    {/* <div className="row justify-content-around">
                            {mySkills.map(skill =>{
                                skill = skill.split(":")
                                return (
                                    <div className="col-4 col-sm-3 col-md text-center skills">
                                        <i className={`fab ${skill[0]} fa-4x`} aria-hidden="true">{skill[0] === 'fa-cuttlefish' ? '++' : null}</i><br/>
                                        <div className="skills">{`${skill[1]}`}</div>
                                    </div>
                                )
                            })}
                    </div>
                    <br/><br/><br/><br/> */}
                    <div className="row justify-content-around">
                        {this.props.mySkills2.map(skill => {
                            return (
                                <div className="col-4 col-sm-3 col-md-2 text-center skills">
                                    <div className="skillsIcon"> {skill[0]} </div>
                                    <div>{`${skill[1]}`}</div>
                                </div>
                            )
                        })}
                    </div>
                </div>


                {/* {skill[1]==="C++"? "BottomIcon" : "skillsIcon"} */}


                {/* History */}
                <br /><br />
                <div className="container">
                    <h3 className="border-bottom text-white text-center">Education & Accomplishments</h3>
                    <br /><br />
                    <div className="row">
                        <div className="col-lg">
                            <h4 className="text-primary">{myHistory.story1.title}</h4>
                            <p className="blockquote text-left">{myHistory.story1.desc}</p>
                        </div>
                        <div className="col-lg">
                            <h4 className="text-warning">{myHistory.story2.title}</h4>
                            <p className="blockquote text-left">{myHistory.story2.desc}</p>
                        </div>
                        <div className="col-lg">
                            <h4 className="text-danger">{myHistory.story3.title}</h4>
                            <p className="blockquote text-left">{myHistory.story3.desc}</p>
                        </div>
                    </div>
                    <div className="row" >
                        <div className="col-lg" style={{ display: "none" }}>
                            <h4 className="text-success">{myHistory.story4.title}</h4>
                            <p className="blockquote text-left">{myHistory.story4.desc}</p>
                        </div>
                        <div className="col-lg" >
                            <h4 className="text-success">{myHistory.story5.title}</h4>
                            <p className="blockquote text-left" dangerouslySetInnerHTML={{ __html: myHistory.story5.desc }}></p>
                        </div>
                        <div className="col-lg">
                            <h4 className="text-primary">{myHistory.story6.title}</h4>
                            <p className="blockquote text-left">{myHistory.story6.desc}</p>
                        </div>
                        <div className="col-lg">
                            <h4 className="text-warning">{myHistory.story7.title}</h4>
                            <p className="blockquote text-left">{myHistory.story7.desc}</p>
                        </div>
                    </div>
                </div><br /><br /><br /><br />
            </div>
        )
    }
}

export default HomePage;